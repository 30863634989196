

  $(function(){
    $('#news').click(function(){
      $(".webs").fadeOut(500);
      $(".emails").fadeIn(1000);
        });
    });
    
    $('#webe').click(function(){
      $(".emails").fadeOut(1000);
      $(".webs").fadeIn(500);   
     });
    
    
    $('#alls').click(function(){
      $(".emails, .webs").fadeIn(500);
    });



  