$(document).ready(function() {
    var isLoadingAnimationVisible = true;

    function showPageContent() {
        $('.loading__animation').fadeOut(1800);
        $('.page__content, #services, #portfolio').fadeIn(1000);
        isLoadingAnimationVisible = false;
    }

    // При полной загрузке страницы запустим анимацию, если она ещё не запустилась
    $(window).on('load', function() {
        if (isLoadingAnimationVisible) {
            showPageContent();
        }
    });

    // Если анимация ещё не запустилась через window.onload, запустим её сейчас
    setTimeout(function() {
        if (isLoadingAnimationVisible) {
            showPageContent();
        }
    }, 5000); // Это время задержки, можно изменить на своё
});

// rocket scripts

$(function(){


$(window).scroll(function(){
    if($(this).scrollTop()>250){
        $('.scroll-to-top').fadeIn();
    }else{
        $('.scroll-to-top').fadeOut();
    }
});

    // Перемещение на верхнюю часть страницы и замена изображения
    $('.scroll-to-top').click(function() {
        $('html, body').animate({scrollTop : 0}, 800, function() {
            // Заменить изображение на гифку
            $('.scroll-to-top img').attr('src', './img/raket.png');
        });
        return false;
    });


})