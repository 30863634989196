$(function(){
  $('.fa-bars').click(function(){
   $('.mobile__menu').removeClass('mobile__hidden');
   $('body').addClass('scrolss__hidden')
  });

  $('.fa-x').click(function(){
    $('.mobile__menu').addClass('mobile__hidden');
    $('body').removeClass('scrolss__hidden')
   });

  });

    